import * as React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useContext } from 'react';
import LocaleContext from '../context/Locale';

const variants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export const MenuItem = ({ item, toggle, index }) => {
    const { name, slug } = item;
    const { activeLocale } = useContext(LocaleContext);

    return (
        <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <StyledLink to={`/${activeLocale === 'en' ? 'en' : 'pl'}${slug}`} onClick={(e) => toggle()}>
                {name}
            </StyledLink>
        </motion.li>
    );
};

const StyledLink = styled(Link)`
    position: relative;
    font-size: 2rem;
    color: var(--color-text-primary);
    line-height: 1.65;
`;
