import * as React from 'react';
import { motion } from 'framer-motion';

const Path = (props) => (
    <motion.path
        fill="transparent"
        strokeWidth="4"
        stroke="hsl(0, 0%, 100%)"
        // strokeLinecap="round"
        {...props}
    />
);

export const MenuToggle = ({ toggle }) => (
    <button
        aria-label={'menu button'}
        onClick={toggle}
        style={{
            outline: 'none',
            border: 'none',
            cursor: 'pointer',
            position: 'fixed',
            top: '18px',
            left: '15px',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: 'transparent',
            zIndex: 101,
        }}
    >
        <svg width="50" height="50" viewBox="0 0 23 23">
            <Path
                variants={{
                    closed: { d: 'M 2 2.5 L 30 2.5' },
                    open: { d: 'M 3 16.5 L 17 2.5' },
                }}
            />
            <Path
                d="M 2 9.423 L 30 9.423"
                variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 },
                }}
                transition={{ duration: 0.1 }}
            />
            <Path
                variants={{
                    closed: { d: 'M 2 16.346 L 30 16.346' },
                    open: { d: 'M 3 2.5 L 17 16.346' },
                }}
            />
        </svg>
    </button>
);
