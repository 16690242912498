import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import LocaleContext from '../context/Locale';
import locales from '../../config/locales';
import { MenuItem } from './MenuItem';
import styled from 'styled-components';
import { LocaleSwitcher } from './LocaleSwitcher/LocaleSwitcher';
import { pxToRem } from '../utils/pxToRem';
import { ROUTES } from '../utils/routes';

const variants = {
    open: {
        // display: 'block',
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
        transition: {
            // staggerChildren: 0.05,
            // delayChildren: 0.2,
            staggerDirection: -1,
        },
        // display: 'none',
    },
};

export const Navigation = ({ toggle }) => {
    // @ts-ignore
    const { activeLocale, updateLocale } = useContext(LocaleContext);

    return (
        <>
            <NavLogo>
                <StaticImage
                    src="../images/logo.png"
                    alt=""
                    placeholder="blurred"
                    style={{ marginTop: '50px' }}
                    loading={'eager'}
                />
            </NavLogo>
            <NavList variants={variants}>
                {/*<div style={{ position: 'relative' }}>*/}
                {/*<LocaleSwitcher />*/}
                {/*<motion.li initial={false} variants={menu} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>*/}

                {/*</motion.li>*/}
                {menuItems.map((i, index) => (
                    <MenuItem item={i} key={i.name} index={index} toggle={toggle} />
                ))}
                {/*<li>*/}
                {/*    <div className="flex items-center">*/}
                {/*        <div className="relative">*/}
                {/*            <select value={activeLocale} onChange={({ target: { value } }) => updateLocale(value)}>*/}
                {/*                {locales.map(({ label, path }, index) => (*/}
                {/*                    <option key={index} value={path}>*/}
                {/*                        {label}*/}
                {/*                    </option>*/}
                {/*                ))}*/}
                {/*            </select>*/}
                {/*            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-lightgray">*/}
                {/*                <svg*/}
                {/*                    className="fill-current h-4 w-4"*/}
                {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                {/*                    viewBox="0 0 20 20"*/}
                {/*                >*/}
                {/*                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />*/}
                {/*                </svg>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*</div>*/}
            </NavList>
        </>
    );
};

const NavList = styled(motion.ul)`
    position: absolute;
    padding: ${pxToRem(25)} 0;
    top: ${pxToRem(200)};
    width: 100%;
    z-index: 103;
    list-style: none;
    text-align: center;
`;

export const NavLogo = styled.div`
    margin: 0 auto;
    max-width: ${pxToRem(250)};
`;

const menuItems = [
    {
        name: 'home',
        slug: '/',
    },
    {
        name: 'projects',
        slug: `/${ROUTES.PROJECTS}/lemon-and-berry`,
    },
    {
        name: 'studio',
        slug: `/${ROUTES.STUDIO}`,
    },
    {
        name: 'crew',
        slug: `/${ROUTES.CREW}`,
    },
    {
        name: 'contact',
        slug: `/${ROUTES.CONTACT}`,
    },
];
