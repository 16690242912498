import React, { createContext, useEffect, useReducer } from "react";
import { navigate } from "gatsby";

import locales from "../../config/locales";
import useLocalStorage from "../hooks/useLocalStorage";

export interface LocaleContextI {
  activeLocale: "en" | "pl";
  updateLocale: (activeLocale: string) => void;
}

const LocaleContext = createContext<LocaleContextI>({
  activeLocale: "en",
  updateLocale: () => {},
});

function reducer(state, { type, node_locale }) {
  switch (type) {
    case "UPDATE_LOCALE":
      return { ...state, activeLocale: node_locale };
    default:
      throw new Error("Invalid action");
  }
}

const defaultLocale = locales.find((node_locale) => node_locale.default);

function LocaleProvider({
  children,
  node_locale = defaultLocale.path,
  location: { pathname, search },
}) {
  const [savedLocale, saveLocale] = useLocalStorage(
    "wjt-store",
    JSON.stringify({
      activeLocale: node_locale,
    })
  );
  const [state, dispatch] = useReducer(reducer, JSON.parse(savedLocale));
  const [, localePath] = pathname.split("/");
  const [, path] = pathname.split(`/${localePath}`);

  const updateLocale = (node_locale) =>
    navigate(`/${node_locale}${path}${search}`);

  useEffect(() => {
    if (
      localePath !== state.activeLocale &&
      locales.map(({ path }) => path).includes(localePath)
    )
      dispatch({ type: "UPDATE_LOCALE", node_locale: localePath });
  }, [localePath, state.activeLocale]);

  useEffect(() => {
    saveLocale(JSON.stringify(state));
  }, [state, saveLocale]);

  return (
    <LocaleContext.Provider
      value={{
        ...state,
        updateLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}

export { LocaleProvider, LocaleContext as default };
