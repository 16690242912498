module.exports = [
  {
    default: true,
    path: `en`,
    label: `English`,
    node_locale: `en`,
  },
  {
    path: `pl`,
    label: `Polski`,
    node_locale: `pl`,
  },
];
