exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-crew-tsx": () => import("./../../../src/pages/crew.tsx" /* webpackChunkName: "component---src-pages-crew-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-movies-tsx": () => import("./../../../src/pages/movies.tsx" /* webpackChunkName: "component---src-pages-movies-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-templates-project-details-tsx": () => import("./../../../src/templates/projectDetails.tsx" /* webpackChunkName: "component---src-templates-project-details-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-studio-template-tsx": () => import("./../../../src/templates/studio-template.tsx" /* webpackChunkName: "component---src-templates-studio-template-tsx" */)
}

