import React, { FC, useRef } from 'react';
import Container from '../container';
import Seo from '../Seo/Seo';
import { Normalize } from 'styled-normalize';
import styled, { createGlobalStyle } from 'styled-components';
import { motion, useCycle } from 'framer-motion';
import { PageProps } from 'gatsby';
import { useDimensions } from '../../hooks/useDimenstions';
import { MenuToggle } from '../MenuToggle/MenuToggle';
import { Navigation } from '../Navigation';
import '@fontsource/cormorant-infant/500.css';
import '@fontsource/cormorant-infant/400.css';
import '@fontsource/cormorant-infant/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import { LocaleProvider } from '../../context/Locale';
import { Footer } from '../Footer';
import { pxToRem } from '../../utils/pxToRem';

const GlobalStyle = createGlobalStyle`
  html {
    --background-primary: #131212;
    --color-text-primary: #fff;
  }
  body {
    background-color: var(--background-primary);
    color: var(--color-text-primary);
    font-family: "Cormorant Infant",sans-serif;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  a {
    text-decoration: none;
  }
  h1,h2,h3,h4 {
    font-weight: 300;
  }
  p {
    line-height: 2;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}

  .gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
  }
  .gmnoprint div {
    background:none !important;
  }

  @keyframes blinkHide {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }

  @keyframes blinkShow {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
  }

  @keyframes rotateIt {
    to {
      transform: rotate(-360deg);
    }
  }

`;

const sidebar = {
    open: (height = 1000) => ({
        display: 'block',
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: 'circle(30px at 40px 40px)',
        transition: {
            delay: 0.5,
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

const overlay = {
    open: {
        display: 'block',
        opacity: 0.5,
        transition: {
            type: 'spring',
            stiffness: 20,
            restDelta: 2,
        },
    },
    closed: {
        opacity: 0,
        transition: {
            delay: 0.5,
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

// @ts-ignore
const Layout: FC<PageProps> = ({ children, location, pageResources, pageContext: { node_locale } }) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const { height } = useDimensions(containerRef);

    return (
        <>
            <LocaleProvider node_locale={node_locale} location={location}>
                <Seo page={pageResources?.page?.componentChunkName} />
                <Normalize />
                <GlobalStyle />
                <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'} custom={height} ref={containerRef}>
                    <NavigationWrapper variants={sidebar}>
                        <Navigation toggle={() => toggleOpen()} />
                    </NavigationWrapper>
                    <Overlay initial={false} variants={overlay} onClick={() => isOpen && toggleOpen()} />

                    <MenuToggle toggle={() => toggleOpen()} />
                </motion.nav>
                <Container>{children}</Container>
                {pageResources?.page?.componentChunkName !== 'component---src-templates-projects-tsx' &&
                    pageResources?.page?.componentChunkName !== 'component---src-pages-studio-tsx' && <Footer />}
            </LocaleProvider>
        </>
    );
};

export default Layout;

export const Overlay = styled(motion.div)`
    z-index: 1;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(20px);

    will-change: opacity;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
`;

const NavigationWrapper = styled(motion.div)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: ${pxToRem(300)};
    background: #000;
    z-index: 101;
    @media (max-width: ${pxToRem(480)}) {
        width: 100%;
    }
`;
