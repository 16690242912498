import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '../utils/pxToRem';

export const Footer: FC = () => {
    const date = new Date().getFullYear();
    return <Wrapper>WJT · LIKAON © {date}</Wrapper>;
};

const Wrapper = styled.div`
    width: 100%;
    padding: ${pxToRem(40)};
    background-color: black;
    margin-top: ${pxToRem(100)};
    box-sizing: border-box;
`;
